import sanitizeHtml from 'sanitize-html';

export const getSanitizedHtml = (html) => {
  return sanitizeHtml(html, {
    allowedTags: [
      'address',
      'article',
      'aside',
      'footer',
      'header',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'hgroup',
      'main',
      'nav',
      'section',
      'blockquote',
      'dd',
      'div',
      'dl',
      'dt',
      'figcaption',
      'figure',
      'hr',
      'li',
      'main',
      'ol',
      'p',
      'pre',
      'ul',
      'a',
      'abbr',
      'b',
      'bdi',
      'bdo',
      'br',
      'cite',
      'code',
      'data',
      'dfn',
      'em',
      'i',
      'kbd',
      'mark',
      'q',
      'rb',
      'rp',
      'rt',
      'rtc',
      'ruby',
      's',
      'samp',
      'small',
      'span',
      'strong',
      'sub',
      'sup',
      'time',
      'u',
      'var',
      'wbr',
      'caption',
      'col',
      'colgroup',
      'table',
      'tbody',
      'td',
      'tfoot',
      'th',
      'thead',
      'tr',
      'iframe'
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      iframe: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading']
    },
    allowedIframeHostnames: ['www.youtube.com']
  });
};

export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidClasswiseAssignmentURL = (url) => {
  const regex = new RegExp(/^https:\/\/[-a-zA-Z0-9]+\.getclasswise\.com\/start\/[0-9a-fA-F]{24}$/);
  return regex.test(url);
};

export const shuffle = (array) => {
  if (!array) {
    return array;
  }

  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const sanitizeMessage = (value) => {
  if (value.indexOf('\\') === -1) {
    return value;
  }
  return value
    .trim()
    .replaceAll('|\\(', '$')
    .replaceAll('\\)', '$')
    .replaceAll('\\[', '$$')
    .replaceAll('\\]', '$$')
    .replaceAll('\\(', '$$')
    .replaceAll('\\)', '$$');
};

export const removeMcqOptions = (value) => {
  return value.replaceAll(/<mcq-option>.*?<\/mcq-option>/g, '');
};

export const getCurrencySymbol = (currencyCode) => {
  return (0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim();
};

export function removeUrlParam(relativeUrl, paramName) {
  // Split the URL into the path and the query string
  const [path, queryString] = relativeUrl.split('?');

  if (!queryString) {
    return relativeUrl; // No query parameters to remove
  }

  // Convert the query string into an array of key-value pairs
  const params = new URLSearchParams(queryString);

  // Delete the specific parameter if it exists
  params.delete(paramName);

  // Reconstruct the URL
  const newQueryString = params.toString();
  const newUrl = newQueryString ? `${path}?${newQueryString}` : path;

  return newUrl;
}
