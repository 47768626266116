import React from 'react';
import { Flex } from '@chakra-ui/react';
import Message from './Message';

export default function ChatMessages({ scrollRef, messages, selectMcqOption }) {
  return (
    <Flex grow="1" align="start" direction="column" overflowY="auto" p="10px" height={'100%'}>
      {!!messages.length &&
        messages.map((msg) => (
          <Message
            key={msg.id}
            id={msg.id}
            isSentByUser={msg.isSentByUser}
            message={msg.message}
            photoURL={msg.photoURL}
            displayName={msg.displayName}
            selectMcqOption={selectMcqOption}
          />
        ))}
      <div ref={scrollRef}></div>
    </Flex>
  );
}
