import { Button, Flex } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { sanitizeMessage } from '../../../utils';
import MarkdownWithKatex from './MarkdownWithKatex';
import { useStableCallback } from '../../../hooks';

const SelectOptions = ({ message, selectMcqOption }) => {
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const options = useMemo(() => {
    const regex = /<mcq-option>(.*?)<\/mcq-option>/g;
    const matches = sanitizeMessage(message).match(regex);
    return matches ? matches.map((match) => match.replace(/<mcq-option>|<\/mcq-option>/g, '')) : [];
  }, [message]);

  const handleOptionSelect = useStableCallback((option) => {
    // If the option is already selected for this group of options, don't allow selection
    if (isOptionSelected) return;
    setIsOptionSelected(true);
    selectMcqOption(option);
  });

  return (
    <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={2} flexWrap="wrap">
      {options.map((option, index) => (
        <Button
          key={index}
          variant="solid"
          size="sm"
          colorScheme="blue"
          className="chat-mcq-option"
          onClick={() => handleOptionSelect(option)}
        >
          <MarkdownWithKatex>{option}</MarkdownWithKatex>
        </Button>
      ))}
    </Flex>
  );
};

export default SelectOptions;
