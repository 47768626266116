import { useCallback, useLayoutEffect, useRef } from 'react';

const useStableCallback = (callback) => {
  const onChangeInner = useRef(callback);
  // Added useLayoutEffect here
  useLayoutEffect(() => {
    onChangeInner.current = callback;
  });
  const stable = useCallback((...args) => onChangeInner.current(...args), []);
  return stable;
};

export { useStableCallback };
