import React, { useCallback, useRef, useState } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { AiOutlineHome } from 'react-icons/ai';
import { v4 as uuidv4 } from 'uuid';
import ChatInput from './ChatInput';
import ChatMessages from './ChatMessage';
import LoggedInUserWrapper from '../../core/LoggedInUserWrapper';
import { useAddQueryParams, useAuth, useStudentChat, useParams, useApp } from '../../../hooks';
import {
  SOCKET_MESSAGE_TYPES,
  SOCKET_STATUS,
  STUDENT_CHAT_ERROR_TYPE,
  STUDENT_CHAT_MESSAGE_TYPES
} from '../../../constants/socket-constants';
import { scrollIntoElement } from '../../../utils/domUtils';
import { FullPageLoader } from '..';
import { useConsumption, useGetPracticeQuestionTopics } from '../../../queries';
import { Link } from 'react-router-dom';
import { useStableCallback } from '../../../hooks';

const ChatMain = (props) => {
  const { title, chatType } = props;
  const { refetch: refetchConsumptionData } = useConsumption();
  const { showPlans } = useApp();
  const { data: practiceTopic } = useGetPracticeQuestionTopics();
  const [messages, setMessages] = useState([]);
  const [shouldResume, setShouldResume] = useState(false);
  const [currentMessageId, setCurrentMessageId] = useState('');
  const { chatTopicId, chatId } = useParams();
  const addQueryParam = useAddQueryParams();
  const { user } = useAuth();
  const currentMessageIdRef = useRef();
  const lastMessage = useRef(null);
  const onMessageRef = useRef(() => {});
  const onReconnectionRef = useRef(() => {});

  currentMessageIdRef.current = currentMessageId;

  const { initialize, sendStudentMessage, endChat, status, isInitialized, resumeChat } =
    useStudentChat({
      onMessageRef,
      onReconnectionRef
    });

  const topicName = React.useMemo(() => {
    return practiceTopic
      ? practiceTopic.find((topic) => topic._id === chatTopicId)?.topic || ''
      : '';
  }, [chatTopicId, practiceTopic]);

  const addNewEmptyMessage = useCallback(() => {
    const newId = uuidv4();
    setCurrentMessageId(newId);
    setMessages((prevState) => [...prevState, { id: newId, message: '' }]);
  }, []);

  const removeLastMessage = useCallback(() => {
    setMessages((state) => state.slice(0, -1));
  }, []);

  const sendStudentMessageHandler = useCallback(
    (msgStr) => {
      sendStudentMessage(msgStr);
      addNewEmptyMessage();
    },
    [addNewEmptyMessage, sendStudentMessage]
  );

  React.useEffect(() => {
    if (chatId) {
      setShouldResume(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Page refresh scenario
    if (status === SOCKET_STATUS.READY && shouldResume && chatId) {
      resumeChat({ threadId: chatId, shouldFetchMessages: true });
    }
  }, [status, chatId, resumeChat, shouldResume]);

  React.useEffect(() => {
    if (status === SOCKET_STATUS.READY && !isInitialized && !chatId) {
      initialize({ topicId: chatTopicId, chatType });
      addNewEmptyMessage();
      onReconnectionRef.current = () => {
        resumeChat({ threadId: chatId, shouldFetchMessages: false });
      };
    }
  }, [
    initialize,
    status,
    chatTopicId,
    addNewEmptyMessage,
    isInitialized,
    chatId,
    resumeChat,
    chatType
  ]);

  const selectMcqOption = useStableCallback((option) => {
    console.log('option', option);
    const chatId = uuidv4();
    if (option !== '')
      setMessages((prevState) => [
        ...prevState,
        ...[
          {
            id: chatId,
            message: option,
            isSentByUser: true,
            photoURL: user?.photoURL,
            displayName: user?.displayName
          }
        ]
      ]);
    sendStudentMessageHandler(option);
    setTimeout(() => {
      const ele = document.getElementById(chatId);
      ele.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  });

  onMessageRef.current = React.useCallback(
    (messagePayload) => {
      const { type: chatName, payload } = messagePayload;
      if (chatName === SOCKET_MESSAGE_TYPES.STUDENT_CHAT) {
        const { type: chatType, data } = payload;

        switch (chatType) {
          case STUDENT_CHAT_MESSAGE_TYPES.INIT:
            if (data?.threadId) {
              addQueryParam({ chatId: data?.threadId });
            }
            break;
          case STUDENT_CHAT_MESSAGE_TYPES.MESSAGE:
            setMessages((prevState) => {
              const existingMessageIndex = prevState.findIndex(
                ({ id }) => id === currentMessageIdRef.current
              );
              const existingMessage = prevState[existingMessageIndex];
              if (!existingMessage) {
                console.error('cannot find a message for this response');
                return prevState;
              }
              const newMessages = [...prevState];
              newMessages[existingMessageIndex] = {
                id: existingMessage.id,
                message: `${existingMessage.message}${data}`
              };
              return newMessages;
            });
            lastMessage.current.scrollIntoView({ behavior: 'smooth' });
            break;
          case STUDENT_CHAT_MESSAGE_TYPES.RESUME:
            if (data?.messages && data?.messages?.length > 0) {
              let chatId = null;
              const fetchedMessages = data.messages.map((message) => {
                const { text, role } = message;
                const isStudentMessage = role === 'student';
                chatId = uuidv4();
                return {
                  id: chatId,
                  message: text,
                  isSentByUser: isStudentMessage,
                  photoURL: isStudentMessage ? user?.photoURL : undefined,
                  displayName: isStudentMessage ? user?.displayName : undefined
                };
              });
              setMessages(fetchedMessages);
              scrollIntoElement(chatId);
              setShouldResume(false);
            }
            break;
          case STUDENT_CHAT_MESSAGE_TYPES.END:
            refetchConsumptionData();
            endChat();
            setCurrentMessageId('');
            break;
          case STUDENT_CHAT_MESSAGE_TYPES.ERROR:
            console.error('AI chat error: ', data);
            if (STUDENT_CHAT_ERROR_TYPE.CREDIT_LIMIT_EXHAUSTED) {
              refetchConsumptionData();
              showPlans();
              removeLastMessage();
            }
        }
      }
    },
    [endChat, addQueryParam, user, refetchConsumptionData, showPlans, removeLastMessage]
  );

  if (shouldResume) {
    return <FullPageLoader />;
  }

  return (
    <LoggedInUserWrapper
      headerProps={{
        title: (
          <Link to={'/'}>
            <AiOutlineHome size={25} />
          </Link>
        ),
        isIconNavigation: true,
        pageTitle: (
          <Box textAlign={'center'}>
            {title}
            <br />{' '}
            <Text fontSize={'xs'} color={'gray'}>
              {topicName}
            </Text>{' '}
          </Box>
        ),
        renderActions: () => null
      }}
    >
      <Flex direction="column" grow="1" margin={'0 auto'} maxWidth={{ base: '100%', md: '60%' }}>
        <Box pt={3} pb={32}>
          <ChatMessages
            scrollRef={lastMessage}
            messages={messages}
            chatType="chats"
            selectMcqOption={selectMcqOption}
          />
          <ChatInput
            scrollRef={lastMessage}
            chatType="chats"
            setMessages={setMessages}
            sendStudentMessage={sendStudentMessageHandler}
          />
        </Box>
      </Flex>
    </LoggedInUserWrapper>
  );
};

export default ChatMain;
