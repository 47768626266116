import { useMemo } from 'react';
import { usePrices, useUserLocation } from '../queries';
import { getCurrencySymbol } from '../utils';
import { useAuth } from './useAuth';

export const usePricingPlans = () => {
  const { token } = useAuth();
  const { data: locationData, isLoading: isLocationDataLoading } = useUserLocation();
  const { data: prices, isLoading: isPricesLoading, isError: isPricesError } = usePrices({ token });

  const filteredPrices = useMemo(() => {
    if (isLocationDataLoading || isPricesLoading || isPricesError) {
      return [];
    }

    return prices.map((price) => {
      const {
        id,
        name,
        unitPrice,
        unitPriceOverrides,
        popular,
        info,
        features,
        billingCycle: { interval }
      } = price;
      let finalCurrency = unitPrice.currencyCode;
      let finalAmount = unitPrice.amount;

      // Check if the user is not from the default country (India)
      if (locationData && locationData?.country !== unitPrice.currencyCode) {
        for (let priceOverride of unitPriceOverrides) {
          const { unitPrice, countryCodes } = priceOverride;
          if (countryCodes.includes(locationData.country)) {
            finalCurrency = unitPrice.currencyCode;
            finalAmount = unitPrice.amount;
            break;
          }
        }
      }

      return {
        id,
        name,
        price: finalAmount / 100,
        currency: getCurrencySymbol(finalCurrency),
        popular: !!popular,
        interval,
        features: features ? features.split(':') : [],
        info
      };
    });
  }, [locationData, prices, isLocationDataLoading, isPricesLoading, isPricesError]);

  return {
    isLoading: isLocationDataLoading || isPricesLoading,
    prices: filteredPrices
  };
};
