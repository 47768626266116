import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserLocationInfo } from '../api';

export const useUserLocation = () => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['user-location'],
    queryFn: getUserLocationInfo,
    staleTime: Infinity,
    retry: 0,
    onError: (error) => {
      console.error('Error fetching user location:', error);
      queryClient.setQueryData(['user-location'], null);
    }
  });
};
