import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';
import parse, { domToReact } from 'html-react-parser';
import FIBInput from './FIBInput';
import { isEmpty, throttle } from 'lodash';

const FIBQuestionBody = ({ elements, errors, setValue, answers }) => {
  const [blankStates, setBlankStates] = useState({});
  const fibText = elements?.[0]?.html;

  // Initial effect to set answers in the local state
  useEffect(() => {
    if (answers && !isEmpty(answers)) {
      setBlankStates(answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const captureBlankAnswer = useMemo(
    () =>
      throttle((blankId, answer) => {
        setBlankStates((state) => {
          // Blank has not changed
          if (state?.[blankId]?.[0] === answer) return state;

          return {
            ...state,
            [blankId]: [answer]
          };
        });
      }, 1000),
    []
  );

  useEffect(() => {
    setValue('answers', blankStates);
  }, [blankStates, setValue]);

  const error = useMemo(() => {
    if (isEmpty(blankStates)) return '';
    const hasEmptyValue = Object.values(blankStates).some((blank) => blank === '');
    if (hasEmptyValue) {
      return 'Blanks are left empty, for a score, please fill in all the blanks.';
    } else {
      return '';
    }
  }, [blankStates]);

  const parseReplaceOptions = useMemo(
    () => ({
      replace: (domNode) => {
        if (!domNode) return;

        // FIB elements contain an outer P tag
        switch (domNode.name) {
          case 'p':
            return (
              <Flex wrap="wrap" alignItems="center">
                {domToReact(domNode?.children, parseReplaceOptions)}
              </Flex>
            );
          case 'ol':
            return (
              <OrderedList pl={3}>{domToReact(domNode?.children, parseReplaceOptions)}</OrderedList>
            );
          case 'li':
            return (
              <ListItem my={2}>
                <Flex alignItems="center">
                  {domToReact(domNode?.children, parseReplaceOptions)}
                </Flex>
              </ListItem>
            );
          case 'fill-in-blanks':
            const blankId = domNode.attribs['blankid'];
            return (
              <FIBInput
                blankId={blankId}
                initialValue={answers?.[blankId]?.[0]}
                onChange={captureBlankAnswer}
              />
            );
        }

        // Inside these P tags, there is either a text node or a blank span embed
        if (domNode.type === 'text') {
          return <Text mx={0.5}>{domNode.data}</Text>;
        }
      }
    }),
    [answers, captureBlankAnswer]
  );

  const parsedHtmlContent = useMemo(() => {
    return parse(fibText, parseReplaceOptions);
  }, [fibText, parseReplaceOptions]);

  return (
    <Box mt="5" w="100%" className="katex-question">
      <Box>
        <Text color={'#686868'}>Fill in the Blanks</Text>
        <Flex my={2} w="95%" flexDir={'column'}>
          <Box fontSize="lg">
            {/* <span className="fib-text" dangerouslySetInnerHTML={{ __html: fibText }}></span> */}
            {parsedHtmlContent}
          </Box>
        </Flex>
        <Flex w="100%" flexDirection={'column'}>
          <Divider />
        </Flex>
      </Box>

      <Text mt={2} color="red">
        {error || errors.answers?.message}
      </Text>
    </Box>
  );
};

export default FIBQuestionBody;
